import React from "react"
import { Link } from "gatsby"

import { Container, Navbar, Nav } from "react-bootstrap"

import LogoFull from "../../images/missionme_logo_full.svg"
import LogoBildmarke from "../../images/Logo_MissionMe_Bildmarke.svg"

const CustomNavbar = ({ pageInfo }) => {
  return (
    <>
      <Navbar variant="dark" expand="lg" id="site-navbar">
        <Container>
          <Link to="/" className="link-no-style">
            <Navbar.Brand>
              <img alt="MissionMe" className="d-sm-block d-md-none" src={LogoBildmarke} />
              <img alt="MissionMe" className="d-none d-md-block" src={LogoFull} />
            </Navbar.Brand>
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Link to="/" className="link-no-style">
                <Nav.Link as="span">Wir</Nav.Link>
              </Link>
              <Link to="/company/" className="link-no-style">
                <Nav.Link as="span" eventKey="company">
                  Unternehmen
                </Nav.Link>
              </Link>
              <Link to="/team/" className="link-no-style">
                <Nav.Link as="span" eventKey="team">
                  Team
                </Nav.Link>
              </Link>
              <Link to="/jobs/" className="link-no-style">
                <Nav.Link as="span" eventKey="jobs">
                  Jobs
                </Nav.Link>
              </Link>
              <Link to="/kontakt/" className="link-no-style">
                <Nav.Link as="span" eventKey="kontakt">
                  Kontakt
                </Nav.Link>
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default CustomNavbar
