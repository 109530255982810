import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"

import Header from "../organisms/header"
import Navbar from "../molecules/navBar"
import Footer from "../molecules/footer"


const Layout = ({ children, pageInfo }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      (
        <>
          <Container id="content" fluid className={`px-0 main ${pageInfo ? pageInfo.pageName : '' }`}>
            <Row noGutters>
              <Col>
                <Header />
              </Col>
            </Row>
            <Navbar pageInfo={pageInfo} />
            <Row noGutters>
              <Col>
                <Container className="mt-5">
                  <main>{children}</main>
                </Container>
              </Col>
            </Row>
          </Container>
          <Container id="footer" fluid className={`px-0 footer ${pageInfo ? pageInfo.pageName : '' }`}>
            <Row noGutters>
              <Col className="footer-col">
                <Footer />
              </Col>
            </Row>
          </Container>
        </>
      )
    )}
  />
)


export default Layout
