import React from "react"
import { Link } from "gatsby"

import { Container, Navbar, Nav } from "react-bootstrap"

const Footer = ({ pageInfo }) => {
  return (
    <>
      <Navbar
        variant="dark"
        expand="lg"
        id="navbar-footer"
        className="navbar navbar-expand-lg"
      >
        <Container>
          <Nav className="ml-md-auto">
            <Link to="/security" className="link-no-style">
              <Nav.Link as="span" eventKey="security">
                Sicherheit
              </Nav.Link>
            </Link>
            <Link to="/impressum" className="link-no-style">
              <Nav.Link as="span" eventKey="impressum">
                Impressum
              </Nav.Link>
            </Link>
            <Link to="/datenschutz" className="link-no-style">
              <Nav.Link as="span" eventKey="datenschutz">
                Datenschutz
              </Nav.Link>
            </Link>
          </Nav>
        </Container>
      </Navbar>
    </>
  )
}

export default Footer
